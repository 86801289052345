(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/game-type-tag/assets/javascripts/game-type-tag.js') >= 0) return;  svs.modules.push('/components/racing/game-type-tag/assets/javascripts/game-type-tag.js');
"use strict";


const {
  gameTypes
} = svs.racing.common.constants;
const {
  getProductName
} = svs.racing.common.strings;
const GameTypeTag = _ref => {
  let {
    gameType,
    isInverted = false
  } = _ref;
  return React.createElement("div", {
    className: "game-type-tag f-medium ".concat(isInverted ? 'inverted' : 'standard')
  }, getProductName(gameType));
};
svs.racing.gameTypeTag.GameTypeTag = GameTypeTag;

 })(window);